import type { HtmlProps } from './types'

const Html: React.FunctionComponent<HtmlProps> = ({
  content = '',
  ...rest
}) => {
  return <div {...rest} dangerouslySetInnerHTML={{ __html: content }} />
}

export default Html
